import { useEffect, useState } from 'react';
import StoryblokClient from 'storyblok-js-client';

const Storyblok = new StoryblokClient({
  accessToken: '3koxfeFUxXuPQrk6kSVJxAtt',
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

export function useStoryblok(originalStory, preview, locale) {
  const [story, setStory] = useState(originalStory);
  function initEventListeners() {
    const { StoryblokBridge } = window;

    if (typeof StoryblokBridge !== 'undefined') {
      const storyblokInstance = new StoryblokBridge({
        language: locale,
      });

      storyblokInstance.on(['change', 'published'], () => {
        location.reload(true);
      });

      storyblokInstance.on('input', (event) => {
        if (story && event.story._uid === story._uid) {
          setStory(event.story);
        }
      });

      storyblokInstance.on('enterEditmode', (event) => {
        Storyblok.get(`cdn/stories/${event.storyId}`, {
          version: 'draft',
          language: locale,
        }).then(({ data }) => {
          if (data.story) {
            setStory(data.story);
          }
        });
      });
    }
  }

  function addBridge(callback) {
    const existingScript = document.getElementById('storyblokBridge');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = '//app.storyblok.com/f/storyblok-v2-latest.js';
      script.id = 'storyblokBridge';
      document.body.appendChild(script);
      script.onload = () => {
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    if (preview) {
      addBridge(initEventListeners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStory(originalStory);
  }, [originalStory]);

  return story;
}

export default Storyblok;
