import React, { useLayoutEffect, useState } from 'react';
import { StoryblokComponent } from '@storyblok/react';
import Storyblok, { useStoryblok } from '../utils/storyblok';
import { ResolveRelations } from '../utils/resolve-relations';

const MAIN_SPACE_NAME = 'immox-team/';

if (typeof document === 'undefined') {
  React.useLayoutEffect = React.useEffect;
}

const sbParams = {
  version: 'draft',
  resolve_relations: ResolveRelations,
};

export default function Page({ story }) {
  const [showChild, setShowChild] = useState(false);

  const enableBridge = true;
  story = useStoryblok(story, enableBridge);

  useLayoutEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }

  return <StoryblokComponent story={story} blok={story.content} />;
}

export async function getStaticProps({ params, preview = false }) {
  const slug = params.slug ? params.slug.join('/') : 'home';

  if (preview) {
    sbParams.version = 'draft';
  }

  const { data } = await Storyblok.get(`cdn/stories/${slug}`, sbParams);

  return {
    props: {
      story: data ? data.story : false,
      preview,
    },
    revalidate: 3600,
  };
}

export async function getStaticPaths() {
  const { data } = await Storyblok.get('cdn/links/', sbParams);

  const paths = [];
  Object.keys(data.links).forEach((linkKey) => {
    if (data.links[linkKey].is_folder) {
      return;
    }

    if (!data.links[linkKey].slug.includes(MAIN_SPACE_NAME) && !data.links[linkKey].slug.includes('global/')) {
      return;
    }

    const slug = data.links[linkKey].slug;
    let splittedSlug = slug.split('/');
    if (slug === 'home') splittedSlug = false;
    paths.push({ params: { slug: splittedSlug } });
  });

  return {
    paths: paths,
    fallback: false,
  };
}
